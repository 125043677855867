import React from 'react'
import Button from './common/Button'

export default function Help() {
  return (
    <div className='bg-black'>
      <div className='container m-auto'>
        <div className='py-28'>

          <a className='btn bg-transparent' >dsfs</a>
          <a className='btn' >dsfs</a>
          <a className='btn md' >dsfs</a>
          <a className='btn lg' >dsfs</a>
          <h2 className='heading mt-4' >Heading Here</h2>
          <h2 className='heading mt-4 ' >Heading Here</h2>
          <h2 className='normal-heading mt-4 ' >Heading Here</h2>
          <Button text="Sign Up For Free" classes="" />

        </div>
      </div>
    </div>
  )
}
